<template>
    <div>        
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <b-button variant="primary" @click="isActiveModal = true">
                            <span class="text-nowrap">
                                Agregar
                            </span>
                        </b-button>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-input-group>
                                <b-form-input v-model="searchQuery" class="d-inline-block _mr-1" placeholder="Buscar por identificador, marca, modelo o placas"/>
                                <b-input-group-prepend>
                                <b-button variant="primary">
                                    <feather-icon icon="SearchIcon" />
                                </b-button>
                                </b-input-group-prepend>                                
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <!-- Operators List -->            
            <b-table
                ref="refTrucksListTable"
                class="position-relative text-small small"
                :items="listTrucks"
                responsive
                :fields="columns"
                primary-key="id"
                show-empty
                empty-text="No se encontraron resultados"
                :busy.sync="loading"
                stacked="md"
                small
            >
                <!-- Empty -->
                <template slot="empty">
                    <div v-if="loading" class="d-flex justify-content-center mb-1">
                        <b-spinner
                            style="width: 3rem; height: 3rem"
                            class="mr-1"
                            label="Loading..."
                            variant="primary"
                        />
                    </div>
                </template>

                <template #cell(vencimiento_Poliza)="data">
                    <span class="text-capitalize">{{ formatDate(data.item.vencimiento_Poliza) }}</span>
                </template>

                <template #cell(ultimo_Servicio)="data">
                    <span class="text-capitalize">{{ formatDate(data.item.ultimo_Servicio) }}</span>
                </template>

               <!-- Actions -->
                <template #cell(actions)="data">
                    <div class="demo-inline-spacing">

                        <b-button v-if="data.item.imagenes.length > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="secondary" class="btn-icon" size="sm"
                            @click="openModalImages(data.item)"
                            v-b-tooltip.hover.right="`Ver imagenes`">
                            <feather-icon icon="EyeIcon" />
                        </b-button>

                        <b-button v-if="data.item.activo" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="warning" class="btn-icon" size="sm"
                            @click="editTruck(data.item)"
                            v-b-tooltip.hover.right="`Editar`">
                            <feather-icon icon="EditIcon" />
                        </b-button>

                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="data.item.activo ? 'danger' : 'dark'" class="btn-icon" size="sm"
                            @click="deleteTruck(data.item)" v-b-tooltip.hover.right="`${data.item.activo ? 'Desactivar' : 'Activar'}`">
                            <feather-icon :icon="data.item.activo ? 'SlashIcon' : 'CheckSquareIcon'" />
                        </b-button>
                    </div>
                </template>
            </b-table>
        </b-card>

        <!-- Modal Operators -->
        <b-modal ref="infoModalRef" id="modalCreateUpdateCamiones" :title="titleModal" no-close-on-backdrop hide-footer
            @cancel="isActiveModal = false" @hidden="closeModal" :visible="isActiveModal" size="md">

            <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
                <!-- BODY -->
                <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                    <b-form ref="formRef" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                        <b-row>
                            <b-col cols="6">
                                <b-form-group label="Identificador" label-for="identifier">
                                    <validation-provider #default="{ errors }" name="identificador" rules="required">
                                    <b-form-input size="sm" id="identifier" v-model="truck.identificador" :state="errors.length > 0 ? false : null"
                                        name="identifier" placeholder="Identificador de la unidad" />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group label="Marca" label-for="brand">
                                    <b-form-input size="sm" id="brand" v-model="truck.marca"
                                        name="brand" placeholder="Marca" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group label="Modelo" label-for="model">
                                    <b-form-input size="sm" id="model" v-model="truck.modelo"
                                        name="model" placeholder="Modelo" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group label="Año" label-for="anio">
                                    <b-form-input size="sm" id="anio" v-model="truck.anio"
                                        name="anio" placeholder="Año" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group label="Placas" label-for="license_plate">
                                    <validation-provider #default="{ errors }" name="año" rules="required">
                                    <b-form-input size="sm" id="license_plate" v-model="truck.placas" :state="errors.length > 0 ? false : null"
                                        name="license_plate" placeholder="Número de placas" />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group label="Aseguradora" label-for="nombre_aseguradora">
                                    <b-form-input size="sm" id="nombre_aseguradora" v-model="truck.nombre_Aseguradora"
                                        name="nombre_aseguradora" placeholder="Nombre aseguradora" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group label="Núm Seguro" label-for="num_Seguro">                                
                                    <b-form-input size="sm" id="num_Seguro" v-model="truck.num_Seguro"
                                        name="num_Seguro" placeholder="Número de seguro" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group label="Vencimiento de Poliza" label-for="vencimiento_Poliza">                                
                                    <flat-pickr v-model="truck.vencimiento_Poliza" id="vencimiento_Poliza" class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                        placeholder="DD/MM/YYYY" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group label="Último Servicio" label-for="vencimiento_Poliza">                                
                                    <flat-pickr v-model="truck.ultimo_Servicio" id="vencimiento_Poliza" class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                        placeholder="DD/MM/YYYY" />
                                </b-form-group>
                            </b-col>                      
                        </b-row>

                        <!-- Optional files -->
                        <!-- <b-row>
                            <b-col v-for="(image, index) in truck.Imagenes" :key="index" cols="12">
                                <div class="d-flex justify-content-between mt-1">
                                    <label for="login-password">Imagen {{ index + 1 }} </label>
                                    <b-link v-if="index === 0" @click="addImage">
                                        <small>+ Agregar</small>
                                    </b-link>

                                    <b-link variant="text-danger" v-else @click="truck.Imagenes.splice(index, 1)">
                                        <small class="text-red text-danger">- Quitar</small>
                                    </b-link>
                                </div>                                
                                <b-form-file v-model="image.imagen" :id="`file-small-${index}`" size="sm"
                                    placeholder="Elija un archivo o suéltelo aquí..."
                                    drop-placeholder="Suelta el archivo aquí..."
                                    label-data-browse="Elegir"
                                    accept="image/*"/>
                            </b-col>
                        </b-row> -->


                        <!-- Form Actions -->                    
                        <div class="text-right mt-2">                       
                            <b-button size="sm" class="mr-2" :disabled="loading" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                                variant="outline-secondary" @click="closeModal">
                                Cancelar
                            </b-button>

                            <b-button size="sm" :disabled="loading" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                                type="submit">
                                Guardar
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </b-overlay>
        </b-modal>

        <b-modal ref="infoModalImagesRef" id="modalImagesCamiones" :title="`Camión: ${truck.identificador} - ${truck.placas}`" no-close-on-backdrop hide-footer
            @cancel="isActiveModalImages = false" @hidden="closeModalImages" :visible="isActiveModalImages" size="md">

            <!-- <b-carousel id="carousel-example-generic" controls indicators>
                <b-carousel-slide v-for="(item, index) in truck.imagenes" :img-src="getImageUrl(item.imagen)" :key="index" class="w-100" />
            </b-carousel> -->
            <b-row>
                <b-col cols="12" class="mb-1" v-for="(item, index) in truck.imagenes" :key="index">
                    <b-img fluid-grow :src="getImageUrl(item.imagen)" />
                </b-col>               
            </b-row>
        </b-modal>
       
    </div>
</template>

<script>
import _ from "lodash";
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BInputGroupPrepend, 
    BInputGroupAppend, 
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip, 
    VBTooltip,
    VBToggle,
    BSpinner,
    BSidebar,
    BOverlay,
    BFormCheckbox,
    BFormFile,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BImg,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import "animate.css";
import draggable from 'vuedraggable'
import store from '@/store';

import WebCamionesProvider from "@/providers/WebCamiones";
import { formatDate } from "@/@core/utils/filter";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import { forEach } from 'postcss-rtl/lib/affected-props';
const WebCamionesResource = new WebCamionesProvider();

if (process.env.MIX_FALLBACK_LOCALE === "es") {
    localize('es');
}

export default {
    name: 'TrucksList',
    components: {
        BCard,
        BRow,
        BCol,
        BForm,
        BInputGroupPrepend, 
        BInputGroupAppend, 
        BInputGroup,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BOverlay,
        BSpinner,
        BTooltip, 
        VBTooltip,
        VBToggle,
        vSelect,
        BFormCheckbox,
        BSidebar,
        draggable,
        flatPickr,
        BFormFile,
        BCarousel,
        BCarouselSlide,
        BContainer,
        BImg,

        //
        ValidationProvider,
        ValidationObserver,
        FeatherIcon,
    },
    directives: {
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            password,
            hideSidebar: false,
            trucks: [],
            selectedFile: null,
            truck: {
                usuario_Id: "string",
                empresa_Id: 0,
                identificador: "",
                marca: "",
                anio: "",
                modelo: "",
                tipo: "",
                placas: "",
                nombre_Aseguradora: "",
                num_Seguro: "",
                vencimiento_Poliza: null,
                num_Frecuencia_Servicio: 0,
                ultimo_Servicio: null,
                activo: true,
                fecha_Alta: "2024-05-23T15:44:46.351Z",
                fecha_Actualizacion: "2024-05-23T15:44:46.351Z",
                Imagenes: [
                    {
                        imagen: null
                    }
                ],
            },
            columns: [               
                // {
                //     key: "id",
                //     label: 'ID',
                // },
                {
                    key: "identificador",
                    label: 'Identificador',
                }, 
                {
                    key: "marca",
                    label: 'Marca',
                },
                {
                    key: 'modelo',
                    label: 'Modelo',
                },
                {
                    key: "anio",
                    label: 'Año',
                },
                {
                    key: "placas",
                    label: 'Placas',
                }, 
                {
                    key: "nombre_Aseguradora",
                    label: 'Aseguradora',
                },
                {
                    key: "num_Seguro",
                    label: 'Núm. Seguro',
                },
                {
                    key: "vencimiento_Poliza",
                    label: 'Vencimiento Poliza',
                },
                {
                    key: "ultimo_Servicio",
                    label: 'Último Servicio',
                },
                { 
                    key: "actions", 
                    label: 'Acciones' 
                },
            ],
            sortBy: "id",
            sortDesc: true,
            searchQuery: null,
            from: 0,
            to: 0,
            loading: false,
            isEdit: false,
            isActiveModal: false,
            isDetail: false,
            titleModal: "Alta de Camiones",
            roles: [],
            isActiveModalImages: false,
        };
    },
    computed: {
        listTrucks () {            
            let result = this.searchQuery ? this.trucks.filter(o =>
                o.identificador?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                o.marca?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                o.modelo?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                o.placas.toLowerCase().includes(this.searchQuery.toLowerCase())
            ) : this.trucks;
            return result
        }
    },
    watch: {
       
    },
    async mounted() {
        await this.getTrucks()
    },
    methods: {    
        addImage() {
            this.truck.Imagenes.push({
                imagen: null,
            })
        },
        openModalImages(item) {
            this.truck = {...item}
            this.isActiveModalImages = true
        },
        closeModalImages() {
            this.isActiveModalImages = false
        },
        closeModal() {
            this.isActiveModal = false
            this.isEdit = false
            this.truck = {
                usuario_Id: "string",
                empresa_Id: 0,
                identificador: null,
                marca: "",
                anio: "",
                modelo: "",
                tipo: "",
                placas: "",
                nombre_Aseguradora: "",
                num_Seguro: "",
                vencimiento_Poliza: null,
                num_Frecuencia_Servicio: 0,
                ultimo_Servicio: null,
                activo: true,
                Imagenes: [
                    {
                        imagen: null
                    }
                ],
            }
            this.titleModal = 'Alta de Camión'
        },
        async onSubmit () {   


            const formData = new FormData();
            delete this.truck.Imagenes;

            // for (const propiedad in this.truck) {
            //     if (this.truck[propiedad] && (propiedad !== 'Imagenes' && propiedad !== 'imagenes')){
            //         formData.append(propiedad, this.truck[propiedad]);
            //     }                
            // }

            // // Verificar si hay imágenes para agregar al FormData
            // if (this.truck.Imagenes.length > 0 && this.truck.Imagenes[0].imagen !== null) {
            //     // Agregar cada imagen al FormData
            //     this.truck.Imagenes.forEach(function(image, index) {
            //         if (image.imagen) {
            //             // formData.append(`imagens[${index}]`, image.imagen);
            //             formData.append('imagens', image.imagen);
            //         }
            //     });
            // }
            // formData.append('empresa_Id', store.getters['auth/getConexionId'])
            
            // formData.delete('imagenes');
            // formData.delete('Imagenes');


            try {
                
                this.loading = true                
                const { data } = this.isEdit ? await WebCamionesResource.update(this.truck) : await WebCamionesResource.store(this.truck);
                this.loading = false
                if (data.isSuccesful) {
                    this.success(data.message);
                    await this.getTrucks()
                    this.isActiveModal = false
                    this.truck = {
                        usuario_Id: "string",
                        empresa_Id: 0,
                        identificador: null,
                        marca: "",
                        anio: "",
                        modelo: "",
                        tipo: "",
                        placas: "",
                        nombre_Aseguradora: "",
                        num_Seguro: "",
                        vencimiento_Poliza: null,
                        num_Frecuencia_Servicio: 0,
                        ultimo_Servicio: null,
                        activo: true,
                        Imagenes: [
                            {
                                imagen: null
                            }
                        ],
                    }
                    this.isEdit = false

                } else {
					this.danger(data.message);
                }
            } catch (e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },       
        editTruck(item) {
            item.Imagenes = [{
                imagen: null
            }]

            this.isEdit = true
            this.truck = {...item}
            this.isActiveModal = true
            this.titleModal = 'Editar Camión'
        },
        async getTrucks() {
            this.loading = true;
            const { data } = await WebCamionesResource.index();
            this.loading = false;
            this.trucks = data.data
        },
        deleteTruck(item) {
            console.log(item)
            this.$swal({
                title: `¿Desea ${item.activo ? 'desactivar' : 'activar'} el camión?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: `Continuar`,
                cancelButtonText: `Cancelr`,
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        this.loading = true
                        const { data } = await WebCamionesResource.destroy(item.id)
                        this.loading = false

                        if (data.isSuccesful) {
                            this.success(data.message)
                            await this.getTrucks()
                        } else {
                            this.$swal.showValidationMessage(
                                `Request failed: ${data.message}`
                            );
                        }
                    }catch(e) {
                        this.$swal.showValidationMessage(
                            `Request failed: ${e}`
                        );
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.custom-file-button + label {
  display: none;
}

.carousel-image {
  width: 300px; /* Establecer el ancho deseado */
  height: 200px; /* Establecer la altura deseada */
  object-fit: cover; /* Para que la imagen cubra el tamaño especificado */
}
</style>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
